export const initPopup = () => {
  // Define the elements
  const popupSection = document.getElementById('RS-section--popup');
  const popupContainer = document.getElementById('RS-container--popup');
  const popupClose = document.getElementById('RS-section--popup__close');
  const popupSectionDisplayedClass = 'RS-section--popup-displayed';
  const popupSectionVisibleClass = 'RS-section--popup-visible';
  const popupClosedKey = 'popupClosed';
  let popupClosed = false;

  // Check if the popup was previously closed
  if (localStorage.getItem(popupClosedKey) === 'true') {
      return; // Do not show the popup again
  }

  // Function to check cursor position and add class
  function checkCursorPosition(event) {
      if (popupClosed) return; // Do not show the popup if it was closed during this session

      const edgeThreshold = 64; // Distance from edge to trigger popup
      const { clientX, clientY, innerWidth, innerHeight } = event;

      if (clientX < edgeThreshold || clientX > innerWidth - edgeThreshold ||
          clientY < edgeThreshold || clientY > innerHeight - edgeThreshold) {
          popupSection.classList.add(popupSectionDisplayedClass);
          setTimeout(() => {
              popupSection.classList.add(popupSectionVisibleClass);
          }, 10);
      }
  }

  // Function to remove class when clicking close button or outside popup
  function handleClick(event) {
      if (event.target === popupClose || event.target.closest('#RS-section--popup__close') || !popupContainer.contains(event.target)) {
          popupSection.classList.remove(popupSectionDisplayedClass);
          popupSection.classList.remove(popupSectionVisibleClass);
          localStorage.setItem(popupClosedKey, 'true'); // Remember that the popup was closed
          popupClosed = true; // Set the flag to prevent showing the popup again during this session
      }
  }

  // Function to show popup on mobile resolutions
  function showPopupOnMobile() {
    if (window.innerWidth <= 860) {
      setTimeout(() => {
        popupSection.classList.add(popupSectionDisplayedClass);
        setTimeout(() => {
            popupSection.classList.add(popupSectionVisibleClass);
        }, 10);
      }, 4000);
    }
  }

  // Add event listeners 5 seconds after page load
  setTimeout(() => {
      if (window.innerWidth > 860) {
          document.addEventListener('mousemove', checkCursorPosition);
      } else {
          showPopupOnMobile();
      }
      document.addEventListener('click', handleClick);
  }, 2000);
};