export const initFormControls = () => {

    // const formSectionAboutProduct = document.getElementById('mc-embedded-subscribe-form-1');
    // const formSectionNewsletter = document.getElementById('mc-embedded-subscribe-form-2');
    const formSectionDietitians = document.getElementById('mc-embedded-subscribe-form-3');

    const handleFormSubmit = (form) => (e) => {
        e.preventDefault();
        const submitInput = form.querySelector('[type="submit"]');
        const submitInputText = form.querySelector('.RS-button__content');
        const submitButtonLoader = form.querySelector('.RS-button__loader');
        submitInput.blur();
        let hasError = false;

        const requiredInputs = form.querySelectorAll('.RS-required');

        requiredInputs.forEach(input => {
            if (input.type === 'text' || input.type === 'email') {
                if (!input.value.trim()) {
                    showError(input, 'To pole jest wymagane');
                    hasError = true;
                } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
                    showError(input, 'Niepoprawny format email');
                    hasError = true;
                } else {
                    hideError(input);
                }
            } else if (input.type === 'radio' && input.classList.contains('RS-input--radio-checkbox')) {
                const checkedRadioCheckboxes = form.querySelectorAll(`input[type="radio"].RS-input--radio-checkbox:checked`);
                if (checkedRadioCheckboxes.length !== 1) {
                    showError(input, 'Zaznaczenie zgody jest wymagane');
                    hasError = true;
                } else {
                    hideError(input);
                }
            } else if (input.type === 'radio' && !input.classList.contains('RS-input--radio-checkbox')) {
                const radioGroup = input.closest('.RS-radio-group');
                const radioButtons = radioGroup.querySelectorAll('input[type="radio"]');
                const checkedRadioButtons = radioGroup.querySelectorAll('input[type="radio"]:checked');
                if (radioButtons.length > 0 && checkedRadioButtons.length === 0) {
                    showError(input, 'To pole jest wymagane');
                    hasError = true;
                } else {
                    hideError(input);
                }
            }
        });

        if (!hasError) {
            const checkedRadioCheckboxes = form.querySelectorAll(`input[type="radio"].RS-input--radio-checkbox:checked`);
            if (checkedRadioCheckboxes.length === 1) {
                form.submit();
                submitInput.disabled = true;
                submitInput.classList.add('RS-submit--is-hidden');
                submitInputText.classList.add('RS-submit-text--is-hidden');
                submitButtonLoader.classList.add('RS-button__loader--is-running');
            }
        } else {
            const firstVisibleError = form.querySelector('.RS-error.RS-error--is-visible');
            if (firstVisibleError) {
                const closestFieldGroup = firstVisibleError.closest('.RS-field-group');
                if (closestFieldGroup) {
                    closestFieldGroup.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }
            }
        }
    };

    formSectionDietitians.addEventListener('submit', handleFormSubmit(formSectionDietitians));
    // formSectionAboutProduct.addEventListener('submit', handleFormSubmit(formSectionAboutProduct));
    // formSectionNewsletter.addEventListener('submit', handleFormSubmit(formSectionNewsletter));

    const addInputListeners = (form) => {
        const textEmailInputs = form.querySelectorAll("input[type='text'], input[type='email']");
        textEmailInputs.forEach(function (input) {
            input.addEventListener("blur", function () {
                if (input.value.trim() === "") {
                    showError(input, 'To pole jest wymagane');
                } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
                    showError(input, 'Niepoprawny format email');
                } else {
                    hideError(input);
                }
            });
            input.addEventListener("keyup", function () {
                if (input.value.trim() === "") {
                    showError(input, 'To pole jest wymagane');
                } else if (input.type === 'email' && !validateEmail(input.value.trim())) {
                } else {
                    hideError(input);
                }
            });
        });

        const radioCheckboxInputLabels = form.querySelectorAll("input[type='radio'].RS-input--radio-checkbox + label");
        radioCheckboxInputLabels.forEach(function (label) {
            const input = label.previousElementSibling;
            input.addEventListener("change", function () {
                const checkedRadioCheckboxes = form.querySelectorAll(`input[type="radio"].RS-input--radio-checkbox:checked`);
                if (checkedRadioCheckboxes.length === 1) {
                    hideError(label);
                }
            });
        });

        const radioInputLabels = form.querySelectorAll("input[type='radio']+ label");
        radioInputLabels.forEach(function (label) {
            const input = label.previousElementSibling;
            input.addEventListener("change", function () {
                const radioGroup = input.closest('.RS-radio-group');
                const radioButtons = radioGroup.querySelectorAll('input[type="radio"]');
                const checkedRadioButtons = radioGroup.querySelectorAll('input[type="radio"]:checked');
                if (radioButtons.length > 0 && checkedRadioButtons.length !== 0) {
                    hideError(label);
                }
            });
        });
    };

    addInputListeners(formSectionDietitians);
    // addInputListeners(formSectionAboutProduct);
    // addInputListeners(formSectionNewsletter);

    function showError(input, message) {
        const errorSpan = input.closest('.RS-field-group').querySelector('.RS-error');
        if (errorSpan) {
            errorSpan.classList.add('RS-error--is-visible');
            errorSpan.textContent = message;
        }
    }

    function hideError(input) {
        const errorSpan = input.closest('.RS-field-group').querySelector('.RS-error');
        if (errorSpan) {
            errorSpan.classList.remove('RS-error--is-visible');
        }
    }

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

};